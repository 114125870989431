@media print {
    body * {
        visibility: hidden;
    }

    #print-content,
    #print-content * {
        visibility: visible;
    }

    #print-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    .pagebreak {
        page-break-before: always;
    }

    /* Ensure header repeats on each page */
    .print-header {
        position: running(header);
    }

    /* Adjust the page margins */
    @page {
        margin: 10mm;
    }

    /* Table Styling */
    .MuiTableCell-root {
        padding: 4px;
        /* Reduce padding for better space utilization */
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .MuiTableHead-root {
        background-color: #f5f5f5;
    }

    /* Reduce font sizes for the printed headers */
    h6,
    .MuiTypography-h6 {
        font-size: 14px;
        /* Adjust header size */
    }

    .MuiTypography-body1 {
        font-size: 12px;
        /* Adjust body text size */
        margin-bottom: 2px;
    }

    /* Adjust the spacing for the content around the tables */
    .table-container {
        margin-top: 5px;
        /* Reduce margin to save space */
    }

    /* Fine-tune any spacing between sections */
    .header-section {
        margin-bottom: 5px;
    }
}

@media screen {

    /* Maintain screen styling while adjusting print */
    .print-header {
        margin-bottom: 10px;
    }

    .MuiTableCell-root {
        padding: 6px;
    }

    .MuiTableHead-root {
        background-color: #f5f5f5;
    }
}